<div class="modal">
  <div class="modal__title">
    {{ 'txt-want-a-free-thermometer' | translate }}
  </div>
  <div class="modal__content">
    <app-button
      uid="info-popup-button"
      text="white"
      color="purple"
      label="{{ 'txt-skip-free-therm' | translate }}"
      (click)="api.select(false)"
    />
    <app-button
      uid="info-popup-button"
      text="white"
      color="purple"
      label="{{ 'txt-accept-free-therm' | translate }}"
      (click)="api.select(true)"
    />
  </div>
</div>
